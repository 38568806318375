import axios from "axios";
import { getHeaders } from ".";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE,
});

// Interceptors to add headers dynamically
axiosInstance.interceptors.request.use(
  (config) => {
    config.headers = { ...config.headers, ...getHeaders() };
    return config;
  },
  (error) => Promise.reject(error)
);

export default axiosInstance;
