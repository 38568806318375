export const currencyMapping = {
  India: "₹",
  "United States": "$",
  "United Kingdom": "£",
  "United Arab Emirates": "Dh",
  "Kingdom of Saudi Arabia": "SR",
  Egypt: "EGP",
  Singapore: "$",
};

export const DEFAULT_VIEW = "sales";

export const viewTypesV1 = {
  SELLER_SALES: "seller-sales",
  SELLER_ADVERTISEMENT: "seller-advertisement",
  SELLER_ANALYTICS: "seller-analytics",
  VENDOR_SALES: "vendor-sales",
  VENDOR_ADVERTISEMENT: "vendor-advertisement",
  VENDOR_ANALYTICS: "vendor-analytics",
};

export const viewTypesV2 = {
  SALES: "sales",
  ADVERTISEMENT: "advertisement",
  ANALYTICS: "primary-analytics",
};

export const casualViewTypesV1 = {
  "Seller Sales Report": "seller-sales",
  "Seller Ads Report": "seller-advertisement",
  "Seller Analytics Report": "seller-analytics",
  "Vendor Sales Report": "vendor-sales",
  "Vendor Ads Report": "vendor-advertisement",
  "Vendor Analytics Report": "vendor-analytics",
};

export const casualViewTypesV2 = {
  sales: "Sales Report",
  advertisement: "Ads Report",
  "primary-analytics": "Analytics Report",
  "detailed-analytics": "Analytics Report",
};

export const viewTypeLabelsV1 = [
  {
    value: "SELLER_SALES",
    label: "Seller Sales",
    key: "seller-sales",
    casualValue: "Seller Sales Report",
  },
  {
    value: "SELLER_ADVERTISEMENT",
    label: "Seller Advertisement",
    key: "seller-advertisement",
    casualValue: "Seller Ads Report",
  },
  {
    value: "SELLER_ANALYTICS",
    label: "Seller Analytics",
    key: "seller-analytics",
    casualValue: "Seller Analytics Report",
  },
  {
    value: "VENDOR_SALES",
    label: "Vendor Sales",
    key: "vendor-sales",
    casualValue: "Vendor Sales Report",
  },
  {
    value: "VENDOR_ADVERTISEMENT",
    label: "Vendor Advertisement",
    key: "vendor-advertisement",
    casualValue: "Vendor Ads Report",
  },
  {
    value: "VENDOR_ANALYTICS",
    label: "Vendor Analytics",
    key: "vendor-analytics",
    casualValue: "Vendor Analytics Report",
  },
];

export const viewTypeLabelsV2 = [
  {
    value: "SALES",
    label: "Sales",
    key: "sales",
    casualValue: "Sales Report",
  },
  {
    value: "ADVERTISEMENT",
    label: "Advertisement",
    key: "advertisement",
    casualValue: "Ads Report",
  },
  {
    value: "PRIMARY_ANALYTICS",
    label: "Primary Analytics",
    key: "primary-analytics",
    casualValue: "Analytics Report",
  },
  {
    value: "DETAILED_ANALYTICS",
    label: "Detailed Analytics",
    key: "detailed-analytics",
    casualValue: "Analytics Report",
  },
];

export const currencyCodeMapping = {
  India: "₹",
  "United States": "$",
  "United Kingdom": "£",
  "United Arab Emirates": "Dh",
  "Kingdom of Saudi Arabia": "SR",
  Egypt: "EGP",
  Singapore: "$S",
};

export const selectStyles = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: "8px",
    boxShadow: "none",
    fontSize: "0.9rem",
    minHeight: "28px",
    width: "150px",
    height: "28px",
    border: "1px solid lightgray",
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "0 8px",
    height: "28px",
  }),
  input: (provided, state) => ({
    ...provided,
    margin: "0px",
  }),

  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: "28px",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: "0 8px",
  }),
  menu: (provided) => ({
    ...provided,
    fontSize: "0.9rem",
    color: "#000",
    wordWrap: "break-word",
  }),
  menuList: (provided) => ({
    ...provided,
    "::-webkit-scrollbar": {
      width: "4px",
      height: "8px",
      borderRadius: "25px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#888",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  }),
  option: (provided, state) => {
    return {
      ...provided,
      color: state.isSelected ? "#fff" : provided.color,
      backgroundColor: state.isSelected ? "#252B48" : provided.backgroundColor,
    };
  },
};

export const multiselectStyles = {
  control: (provided, state) => ({
    ...provided,
    borderRadius: "8px",
    boxShadow: "none",
    fontSize: "0.9rem",
    border: "1px solid lightgray",
    minWidth: "100px",
  }),
  menu: (provided) => ({
    ...provided,
    fontSize: "0.9rem",
    color: "#000",
    wordWrap: "break-word",
  }),
  menuList: (provided) => ({
    ...provided,
    "::-webkit-scrollbar": {
      width: "4px",
      borderRadius: "25px",
    },
    "::-webkit-scrollbar-track": {
      background: "#f1f1f1",
    },
    "::-webkit-scrollbar-thumb": {
      background: "#888",
    },
    "::-webkit-scrollbar-thumb:hover": {
      background: "#555",
    },
  }),
  option: (provided, state) => {
    return {
      ...provided,
      color: state.isSelected ? "#fff" : provided.color,
      backgroundColor: state.isSelected ? "#252B48" : provided.backgroundColor,
    };
  },
};

export const comingSoon = {
  "Accounting.REFILL_AD_WALLET": {
    endDate: new Date("03/01/2025"),
    label:
      "Refill Ads Wallet - Monitor your ad spend and recharge your Ads Wallet on the go.",
  },
  "Performance.KEYWORDS": {
    endDate: new Date("03/29/2025"),
    label:
      "Keywords - Here you will find out the following: i) Top Performing Keywords for ads ii) Top products as per organic keyword search for each marketplace. iii) On search keyword stats",
  },
};

export const salesNuggetAndChartNumbers = {
  amazon_seller: {
    nugget: {
      start: 1,
      end: 5,
    },
    chart: {
      start: 6,
      end: 11,
    },
  },
  amazon_vendor: {
    nugget: {
      start: 1,
      end: 7,
    },
    chart: {
      start: 9,
      end: 13,
    },
  },
  flipkart: {
    nugget: {
      start: 1,
      end: 5,
    },
    chart: {
      start: 6,
      end: 11,
    },
  },
  shopify: {
    nugget: {
      start: 1,
      end: 5,
    },
    chart: {
      start: 6,
      end: 13,
    },
    skip: [7, 8],
  },
  nykaa_b2b: {
    nugget: {
      start: 1,
      end: 7,
    },
    chart: {
      start: 9,
      end: 11,
    },
    skip: [13],
  },
  noon_b2c: {
    nugget: {
      start: 1,
      end: 2,
    },
    chart: {
      start: 9,
      end: 10,
    },
    skip: [7, 8, 9, 10, 11],
  },
  meesho_b2c: {
    nugget: {
      start: 1,
      end: 2,
    },
    chart: {
      start: 9,
      end: 11,
    },
    skip: [7, 8, 9, 11],
  },
  pharmeasy_b2c: {
    nugget: {
      start: 1,
      end: 2,
    },
    chart: {
      start: 6,
      end: 11,
    },
    skip: [7, 8, 9, 11],
  },
  all: {
    nugget: {
      start: 1,
      end: 5,
    },
    chart: {
      start: 6,
      end: 11,
    },
    skip: [4, 7, 8, 9, 10],
  },
  jiomart_b2c: {
    nugget: {
      start: 1,
      end: 2,
    },
    chart: {
      start: 6,
      end: 10,
    },
    skip: [7, 8, 9, 11],
  },
  bigbasket_b2b: {
    nugget: {
      start: 1,
      end: 7,
    },
    chart: {
      start: 9,
      end: 11,
    },
    skip: [13],
  },
  swiggy_b2b: {
    nugget: {
      start: 1,
      end: 7,
    },
    chart: {
      start: 9,
      end: 11,
    },
    skip: [12, 13],
  },
  blinkit_b2b: {
    nugget: { start: 1, end: 7 },
    chart: {
      start: 9,
      end: 11,
    },
  },
  zepto_b2b: {
    nugget: { start: 1, end: 7 },
    chart: {
      start: 9,
      end: 11,
    },
  },
};

export const VIEWS_CHART_COUNT = {
  sales: {
    nugget: {
      start: 1,
      end: 9,
    },
    chart: { start: 6, end: 14 },
  },
  ads: {
    nugget: {
      start: 1,
      end: 6,
    },
    chart: {
      start: 6,
      end: 12,
    },
  },
  primaryAnalytics: {
    chart: {
      start: 1,
      end: 13,
    },
  },
  detailedAnalytics: {
    chart: {
      start: 9,
      end: 12,
    },
  },
};
